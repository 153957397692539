import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type UserState = {
  user: string
  username: string
  userLang: 'en' | 'ru'
  isUserHasSubscription: boolean
  setJWTToken: (data: string) => void
  setUserName: (username: string) => void
  setUserLang: (lang: 'en' | 'ru') => void
  setIsUserHasSubscription: (isUserHasSubscription: boolean) => void
  logout: () => void
}

export const useUserStore = create<UserState>()(
  persist(
    set => ({
      user: '',
      username: '',
      userLang: 'en',
      isUserHasSubscription: false,
      yandexToken: '',
      setJWTToken: data => set({ user: data }),
      setUserName: username => set({ username }),
      setUserLang: lang => set({ userLang: lang }),
      setIsUserHasSubscription: isUserHasSubscription => set({ isUserHasSubscription }),
      logout: () => set({ user: '', username: '' }),
    }),
    {
      name: 'user-storage',
    },
  ),
)
