import React from 'react'
import { ChartWrapper, ItemsWrapper, ItemWrapper, NoDataWrapper } from './styled'
import Text from 'Components/Kit/Text'
import { useTranslation } from 'Hooks/useTranslation'
import { ISentimentOverTimePlotItem } from 'Types/charts'
import SentimentLine from '../SentimentLine'
import { css } from 'styled-components'

type FourPchartProps = {
  placeData: ISentimentOverTimePlotItem[] | undefined
  promotionData: ISentimentOverTimePlotItem[] | undefined
  priceData: ISentimentOverTimePlotItem[] | undefined
  productData: ISentimentOverTimePlotItem[] | undefined
}
const FourPchart = ({
  productData,
  priceData,
  promotionData,
  placeData,
}: FourPchartProps) => {
  const t = useTranslation()

  return (
    <ChartWrapper>
      <Text fontSize={20} fontWeight={500}>
        {t('FourPChart.title')}
      </Text>
      {!(placeData && productData && priceData && promotionData) ? (
        <NoDataWrapper>
          <Text fontSize={14} fontWeight={400}>
            {t('FourPChart.noData')}
          </Text>
        </NoDataWrapper>
      ) : (
        <ItemsWrapper>
          {productData && (
            <ItemWrapper>
              <SentimentLine
                data={productData}
                title={t('FourPChart.product')}
                customStyleWrapper={css`padding: 0`}
              />
            </ItemWrapper>
          )}
          {priceData && (
            <ItemWrapper>
              <SentimentLine
                data={priceData}
                title={t('FourPChart.price')}
                customStyleWrapper={css`padding: 0`}
              />
            </ItemWrapper>
          )}
          {promotionData && (
            <ItemWrapper>
              <SentimentLine
                data={promotionData}
                title={t('FourPChart.promotion')}
                customStyleWrapper={css`padding: 0`}
              />
            </ItemWrapper>
          )}
          {placeData && (
            <ItemWrapper>
              <SentimentLine
                data={placeData}
                title={t('FourPChart.place')}
                customStyleWrapper={css`padding: 0`}
              />
            </ItemWrapper>
          )}
        </ItemsWrapper>
      )}
    </ChartWrapper>
  )
}

export default FourPchart
