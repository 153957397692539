import React, { useState } from 'react'
import { useFetch } from 'Hooks/useFetch'
import {
  Container,
  ContentWrapper,
  NoDataOrLoaderWrapper,
  ReviewsWrapper,
  Wrapper,
} from './styled'
import SubHeaderWithFilters from 'Components/SubHeaderWithFilters'
import { IMarketplace } from 'Types/marketplace'
import { TopicsResponseType } from 'Types/topic'
import { Pagination } from 'antd'
import { useTranslation } from 'Hooks/useTranslation'
import { ReviewsResponseType } from 'Types/review'
import Review from 'Components/ReviewsList/Review'
import { useFiltersStore } from 'Store/useFiltersStore'
import Loader from 'Components/Kit/Loader'
import SimpleSearch from 'Components/SimpleSearch'

const reviewsPerPage = 10

const Reviews = () => {
  const t = useTranslation()
  const { activePeriod } = useFiltersStore()

  const [activeMarketplace, setActiveMarketplace] = useState<string>('')
  const [activePage, setActivePage] = useState(1)
  const [activeTopics, setActiveTopics] = useState<{ id: string; name: string }[]>([])

  const { data: topics } = useFetch<TopicsResponseType | null>('topics/', null)

  const { data: marketplaces } = useFetch<IMarketplace[]>('marketplaces/me/', [])

  const { data: reviews } = useFetch<ReviewsResponseType | null>('reviews/', null, {
    offset: (activePage - 1) * reviewsPerPage,
    limit: reviewsPerPage,
    marketplace: activeMarketplace,
    date__gte: activePeriod.startDate,
    date__lte: activePeriod.endDate,
    topic: activeTopics[0]?.id || '',
  })

  const handleActiveMarketplaceChange = (marketplaceName: string) => {
    setActiveMarketplace(marketplaceName)
    setActivePage(1)
  }
  const handleTopicChange = (topicNames: { id: string; name: string }[]) => {
    setActiveTopics(topicNames)
    setActivePage(1)
  }
  const handlePageChange = (page: number) => {
    setActivePage(page)
  }

  const modifiedTopics = topics?.results.map(topic => ({
    id: topic.id.toString(),
    name: topic.name,
  }))

  return (
    <Container>
      {!(topics && reviews) ? (
        <NoDataOrLoaderWrapper>
          <Loader size={25} />
        </NoDataOrLoaderWrapper>
      ) : (
        <Wrapper>
          {reviews && reviews.results.length ? (
            <>
              <SubHeaderWithFilters
                title={t('Reviews.title')}
                marketplaces={marketplaces}
                handleActiveMarketplaceChange={handleActiveMarketplaceChange}
              />
              <ContentWrapper>
                <SimpleSearch
                  elems={modifiedTopics || []}
                  handleChange={handleTopicChange}
                  activeElem={activeTopics}
                  maxSelectableElems={1}
                />
                <ReviewsWrapper>
                  <Pagination
                    current={activePage}
                    total={reviews.count}
                    pageSize={reviewsPerPage}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                  {reviews.results.map(review => (
                    <Review key={review.id} review={review} topics={review.topics} />
                  ))}
                </ReviewsWrapper>
              </ContentWrapper>
            </>
          ) : (
            <NoDataOrLoaderWrapper>{t('Reviews.noData')}</NoDataOrLoaderWrapper>
          )}
        </Wrapper>
      )}
    </Container>
  )
}

export default Reviews
