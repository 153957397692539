import React, { useEffect, useState } from 'react'
import {
  AverageBlocksWrapper,
  ChartsWrapper,
  Container,
  DashboardWrapper,
  LoaderWrapper,
  NpsChartWrapper,
  SentimentChartWrapper,
  Wrapper,
} from './styled'
import TopItems from 'Components/TopItems'
import DiscussedTopics from 'Components/DiscussedTopics'
import SimpleAverageRating from 'Components/Charts/SimpleAvgRating'
import AverageSentiment from 'Components/Charts/AvgSentiment'
import { useParams } from 'react-router-dom'
import Loader from 'Components/Kit/Loader'
import TopicTable from 'Components/TopicTable'
import { IRouteParams } from 'Router/types'
import DownloadReport from 'Components/DownloadReport'
import { generateReport } from 'Utils/report'
import { useTranslation } from 'Hooks/useTranslation'
import ReviewsModal from 'Widgets/Modals/ReviewsModal'
import Select from 'Components/Kit/Select'
import NiceModal from '@ebay/nice-modal-react'
import SentimentLine from 'Components/Charts/SentimentLine'
import { useIdsStore } from 'Store/useIdsStore'
import { useFiltersStore } from 'Store/useFiltersStore'
import { useGroupData } from './Hooks/useGroupData'
import { useFetch } from 'Hooks/useFetch'
import { IReviewReport } from 'Types/report'
import AnalyzeModal from 'Components/AnalyzeModal'
import { SentimentAndNpsWrapper } from '../Overview/styled'
import NpsChart from 'Components/Charts/NpsChart'
import SubHeaderWithFilters from 'Components/SubHeaderWithFilters'
import FourPChart from '../../Components/Charts/FourPChart'

const Group = () => {
  const t = useTranslation()
  const { id } = useParams<keyof IRouteParams>() as IRouteParams
  const { setGroupId } = useIdsStore()
  const { activePeriod } = useFiltersStore()

  const [topProductsType, setTopProductsType] = useState(0)
  const [discussedTopicsType, setDiscussedTopicsType] = useState(0)
  const [activeMarketplace, setActiveMarketplace] = useState<string>('')
  const [activeSentimentPlotTopic, setActiveSentimentPlotTopic] = useState('')

  const {
    rating,
    group,
    topics,
    discussedTopics,
    topItems,
    marketplaces,
    sentiment,
    lineChartData,
    nps,
    fourPDataProduct,
    fourPDataPlace,
    fourPDataPrice,
    fourPDataPromotion,
  } = useGroupData({
    date__gte: activePeriod.startDate,
    date__lte: activePeriod.endDate,
    activeSentimentPlotTopic,
    activeMarketplace,
    id,
    discussedTopicsType,
    topProductsType,
  })

  const { data: groupAnalyzeData } = useFetch<{ show: true } | null>(
    `categories/${id}/is_analysing`,
    null,
  )

  const { data: reviewsReport, fetchData: reviewsReportFetch } = useFetch<
    IReviewReport[]
  >(
    'reviews/download/',
    [],
    {
      category: +id,
    },
    true,
  )
  const handleActiveMarketplaceChange = (marketplaceName: string) => {
    setActiveMarketplace(marketplaceName)
  }
  const showReviewsModal = () => {
    NiceModal.show(ReviewsModal, { id })
  }

  useEffect(() => {
    setGroupId(+id)
  }, [])

  useEffect(() => {
    if (!reviewsReport.length) return
    generateReport(reviewsReport, t('Group.report'), t('Group.report'))
  }, [reviewsReport])

  if (groupAnalyzeData && !groupAnalyzeData.show) {
    NiceModal.show(AnalyzeModal)
    return <div></div>
  }

  const modifiedTopics =
    topics &&
    topics.results.map(topic => ({
      label: topic.name,
      value: topic.id.toString(),
    }))

  const isFourPData =
    fourPDataPlace || fourPDataProduct || fourPDataPromotion || fourPDataPrice

  return (
    <Container>
      {!topics ? (
        <LoaderWrapper>
          <Loader size={25} />
        </LoaderWrapper>
      ) : (
        <Wrapper>
          <SubHeaderWithFilters
            title={group ? group.name : ''}
            marketplaces={marketplaces}
            handleActiveMarketplaceChange={handleActiveMarketplaceChange}
            extraActions={<DownloadReport onClick={reviewsReportFetch} />}
          />
          <DashboardWrapper>
            <ChartsWrapper>
              <TopItems
                items={topItems}
                type={topProductsType}
                setType={setTopProductsType}
              />
              <DiscussedTopics
                topics={discussedTopics}
                type={discussedTopicsType}
                setType={setDiscussedTopicsType}
              />
              <AverageBlocksWrapper>
                {rating && (
                  <SimpleAverageRating
                    rating={rating.average_rating}
                    difference={rating.difference}
                  />
                )}
                {sentiment && (
                  <AverageSentiment
                    percentage={+(sentiment.score * 100).toFixed(0)}
                    difference={sentiment.difference}
                  />
                )}
              </AverageBlocksWrapper>
            </ChartsWrapper>
            <SentimentAndNpsWrapper>
              <SentimentChartWrapper>
                <SentimentLine
                  data={lineChartData?.results || []}
                  extraFilters={
                    <Select
                      variant={'small'}
                      items={modifiedTopics || []}
                      title={t('Group.filterTopics')}
                      onChange={setActiveSentimentPlotTopic}
                    />
                  }
                />
              </SentimentChartWrapper>
              <NpsChartWrapper>
                <NpsChart nps={nps} />
              </NpsChartWrapper>
            </SentimentAndNpsWrapper>
            {isFourPData && (
              <FourPChart
                placeData={fourPDataPlace?.results}
                priceData={fourPDataPrice?.results}
                productData={fourPDataProduct?.results}
                promotionData={fourPDataPromotion?.results}
              />
            )}
            <TopicTable
              topics={topics.results || []}
              width={1360}
              onTotalReviewsClick={showReviewsModal}
            />
          </DashboardWrapper>
        </Wrapper>
      )}
    </Container>
  )
}

export default Group
