import React, { useMemo } from 'react'
import Table, { ColumnType } from 'Components/Kit/Table'
import Text from 'Components/Kit/Text'
import Sort from 'Assets/Sort'
import { ITopic } from 'Types/topic'
import SentimentSimpleChart from 'Components/Charts/SentimentSimple'
import styled, { css, useTheme } from 'styled-components'
import { Tooltip } from 'antd'
import SentimentChartTooltip from 'Components/Charts/SentimentChartTooltip'
import ItemImage from 'Components/ItemImage'
import { useTranslation } from 'Hooks/useTranslation'
import { getMarketplaceIcon } from 'Utils/marketplace'

type TopicsTableProps = {
  topics: ITopic[]
  width: number
  onTotalReviewsClick?: () => void
}
export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
`
const TopicTable = ({ topics, width, onTotalReviewsClick }: TopicsTableProps) => {
  const theme = useTheme()
  const t = useTranslation()

  const columns: ColumnType<ITopic>[] = useMemo(
    () => [
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('TopicTable.topic').toUpperCase()}
          </Text>
        ),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortIcon: () => <Sort />,
        render: (name: string, record: ITopic) => (
          <ItemWrapper
            onClick={
              record.nestingLevel === 2
                ? () => window.open(record.details.source_link, '_target')
                : () => {}
            }
          >
            {record.nestingLevel === 1 && <ItemImage link={record.details.imgUrl} />}
            <Text
              fontSize={14}
              fontWeight={400}
              styledFragment={css`
                width: 312px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;`}
            >
              {name}
            </Text>
          </ItemWrapper>
        ),
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('TopicTable.source').toUpperCase()}
          </Text>
        ),
        dataIndex: 'details',
        key: 'details',
        render: (details: { source_link: string; source_name: string }) => {
          const isMultiple = details.source_name === 'Multiple'
          return isMultiple ? (
            <Text fontSize={14} fontWeight={400}>
              {t('TopicTable.multiple')}
            </Text>
          ) : (
            <>
              {details.source_name === 'Custom data' ? (
                <Text
                  fontSize={14}
                  fontWeight={400}
                  onClick={() => window.open(details.source_link, '_target')}
                >
                  -
                </Text>
              ) : (
                <img src={getMarketplaceIcon(details.source_name)} alt="" />
              )}
            </>
          )
        },
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('TopicTable.totalReviews').toUpperCase()}
          </Text>
        ),
        dataIndex: 'mentionCount',
        key: 'mentionCount',
        sorter: (a, b) => a.mentionCount - b.mentionCount,
        sortIcon: () => <Sort />,
        render: (mentionCount: number) => (
          <Text
            fontSize={14}
            fontWeight={400}
            onClick={onTotalReviewsClick}
            styledFragment={css`text-decoration: underline; cursor: pointer`}
          >
            {mentionCount}
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('TopicTable.positive').toUpperCase()}
          </Text>
        ),
        dataIndex: 'positiveCount',
        key: 'positiveCount',
        sorter: (a, b) => a.positiveCount - b.positiveCount,
        sortIcon: () => <Sort />,
        render: (positiveCount: number) => (
          <Text fontSize={14} fontWeight={400} color={theme.colors.text.green}>
            {positiveCount}
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('TopicTable.neutral').toUpperCase()}
          </Text>
        ),
        dataIndex: 'neutralCount',
        key: 'neutralCount',
        sorter: (a, b) => a.neutralCount - b.neutralCount,
        sortIcon: () => <Sort />,
        render: (neutralCount: number) => (
          <Text fontSize={14} fontWeight={400} color={theme.colors.text.blue}>
            {neutralCount}
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('TopicTable.negative').toUpperCase()}
          </Text>
        ),
        dataIndex: 'negativeCount',
        key: 'negativeCount',
        sorter: (a, b) => a.negativeCount - b.negativeCount,
        sortIcon: () => <Sort />,
        render: (negativeCount: number) => (
          <Text fontSize={14} fontWeight={400} color={theme.colors.text.red}>
            {negativeCount}
          </Text>
        ),
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('TopicTable.toneOfReviews').toUpperCase()}
          </Text>
        ),
        dataIndex: 'totalReviewsPartition',
        key: 'totalReviewsPartition',
        render: (totalReviewsPartition: { p: number; n: number; i: number }) => (
          <Tooltip title={<SentimentChartTooltip sentiments={totalReviewsPartition} />}>
            <span>
              <SentimentSimpleChart data={totalReviewsPartition} />
            </span>
          </Tooltip>
        ),
      },
    ],
    [topics.length],
  )

  return <Table tableData={topics} columns={columns} width={width} />
}

export default TopicTable
