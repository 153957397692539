import styled from 'styled-components'

export const AddCategoryButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 56px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks?.blue};
  cursor: pointer;
  transition: all 0.3s ease;
  

  &:hover {
    background: ${p => p.theme.colors.button.hover};
  }
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`
export const ScrollWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  overflow-x: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
`
