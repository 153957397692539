import React, { useEffect, useState } from 'react'
import {
  BlocksWrapper,
  ChartsWrapper,
  Container,
  DashboardWrapper,
  LoaderWrapper,
  NpsChartWrapper,
  Wrapper,
} from './styled'
import { useParams } from 'react-router-dom'
import CommonInfo from './Components/CommonInfo'
import DiscussedTopics from 'Components/DiscussedTopics'
import Reviews from 'Widgets/Item/Components/Reviews'
import Loader from 'Components/Kit/Loader'
import TopicTable from 'Components/TopicTable'
import { useFetch } from 'Hooks/useFetch'
import { IRouteParams } from 'Router/types'
import DownloadReport from 'Components/DownloadReport'
import { IReviewReport } from 'Types/report'
import { generateReport } from 'Utils/report'
import { useTranslation } from 'Hooks/useTranslation'
import ReviewsModal from '../Modals/ReviewsModal'
import Select from 'Components/Kit/Select'
import NiceModal from '@ebay/nice-modal-react'
import SentimentLine from 'Components/Charts/SentimentLine'
import { useIdsStore } from 'Store/useIdsStore'
import { useFiltersStore } from 'Store/useFiltersStore'
import { useItemData } from './Hooks/useItemData'
import AnalyzeModal from 'Components/AnalyzeModal'
import NpsChart from 'Components/Charts/NpsChart'
import SubHeaderWithFilters from 'Components/SubHeaderWithFilters'
import TopicsDonutChart from '../../Components/Charts/TopicsDonutChart'
import FourPChart from '../../Components/Charts/FourPChart'

const Item = () => {
  const t = useTranslation()
  const { id } = useParams<keyof IRouteParams>() as IRouteParams
  const { setItemId } = useIdsStore()
  const { activePeriod } = useFiltersStore()

  const [discussedTopicsType, setDiscussedTopicsType] = useState(0)
  const [activeReviewsType, setActiveReviewsType] = useState(0)
  const [activeMarketplace, setActiveMarketplace] = useState<string>('')
  const [activeReviewsTopic, setActiveReviewsTopic] = useState('')
  const [activeSentimentPlotTopic, setActiveSentimentPlotTopic] = useState('')

  const {
    topics,
    discussedTopics,
    item,
    rating,
    reviews,
    lineChartData,
    marketplaces,
    sentiment,
    nps,
    popularTopics,
    fourPDataProduct,
    fourPDataPlace,
    fourPDataPrice,
    fourPDataPromotion,
  } = useItemData({
    id,
    date__gte: activePeriod.startDate,
    date__lte: activePeriod.endDate,
    discussedTopicsType,
    activeReviewsType,
    activeReviewsTopic,
    activeSentimentPlotTopic,
    activeMarketplace,
  })

  const { data: productAnalyzeData } = useFetch<{ show: true } | null>(
    `products/${id}/is_analysing`,
    null,
  )

  const handleActiveMarketplaceChange = (marketplaceName: string) => {
    setActiveMarketplace(marketplaceName)
  }

  const { data: reviewsReport, fetchData: reviewsReportFetch } = useFetch<
    IReviewReport[]
  >(
    'reviews/download/',
    [],
    {
      product: +id,
    },
    true,
  )
  const showReviewsModal = () => {
    NiceModal.show(ReviewsModal, { id })
  }

  useEffect(() => {
    setItemId(+id)
  }, [])

  useEffect(() => {
    if (!reviewsReport.length) return
    generateReport(reviewsReport, t('Item.report'), t('Item.report'))
  }, [reviewsReport])

  if (productAnalyzeData && !productAnalyzeData.show) {
    NiceModal.show(AnalyzeModal)
    return <div></div>
  }

  const modifiedTopics = topics
    ? topics.results.map(topic => ({
        label: topic.name,
        value: topic.id.toString(),
      }))
    : []

  const isFourPData =
    fourPDataPlace || fourPDataProduct || fourPDataPromotion || fourPDataPrice

  return (
    <Container>
      {!topics ? (
        <LoaderWrapper>
          <Loader size={25} />
        </LoaderWrapper>
      ) : (
        <Wrapper>
          <SubHeaderWithFilters
            title={item?.name || ''}
            marketplaces={marketplaces}
            handleActiveMarketplaceChange={handleActiveMarketplaceChange}
            extraActions={<DownloadReport onClick={reviewsReportFetch} />}
          />
          <BlocksWrapper>
            {item && rating && sentiment && (
              <CommonInfo item={item} sentiment={sentiment} rating={rating} />
            )}
            <DashboardWrapper>
              <ChartsWrapper>
                <DiscussedTopics
                  topics={discussedTopics}
                  type={discussedTopicsType}
                  setType={setDiscussedTopicsType}
                />
                <SentimentLine
                  data={lineChartData?.results || []}
                  extraFilters={
                    <Select
                      variant={'small'}
                      items={modifiedTopics}
                      title={t('Item.topicsFilter')}
                      onChange={setActiveSentimentPlotTopic}
                    />
                  }
                />
              </ChartsWrapper>
              <ChartsWrapper>
                <NpsChartWrapper>
                  <NpsChart nps={nps} />
                </NpsChartWrapper>
                <TopicsDonutChart data={popularTopics} />
              </ChartsWrapper>
              {isFourPData && (
                <FourPChart
                  placeData={fourPDataPlace?.results}
                  priceData={fourPDataPrice?.results}
                  productData={fourPDataProduct?.results}
                  promotionData={fourPDataPromotion?.results}
                />
              )}
              <TopicTable
                topics={topics.results || []}
                width={1158}
                onTotalReviewsClick={showReviewsModal}
              />
              <Reviews
                topics={topics.results || []}
                reviews={reviews?.results || []}
                setActiveReviewsTopic={setActiveReviewsTopic}
                setActiveReviewsType={setActiveReviewsType}
                activeReviewsType={activeReviewsType}
              />
            </DashboardWrapper>
          </BlocksWrapper>
        </Wrapper>
      )}
    </Container>
  )
}

export default Item
