import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { light } from 'Stylesheets/Theme.styled'
import Header from 'Widgets/Header'
import NiceModal from '@ebay/nice-modal-react'
import { Outlet, useNavigate } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { messages } from '../Localization'
import { useUserStore } from '../Store/useUserStore'
import { Container, GlobalStyles } from './styled'
import { QuotaType } from '../Types/quota'
import { useFetch } from '../Hooks/useFetch'
import { IUser } from '../Types/user'
import { useBillingStore } from '../Store/useBillingStore'
import { YMInitializer } from 'react-yandex-metrika'
import * as amplitude from '@amplitude/analytics-browser'

const { Provider: NiceModalProvider } = NiceModal
const Layout = () => {
  const { userLang, user, setIsUserHasSubscription, setUserLang } = useUserStore()
  const { setQuota } = useBillingStore()
  const navigate = useNavigate()

  const { data: quotaResponseData, fetchData: getUserQuota } = useFetch<QuotaType | null>(
    'quotas/me/',
    null,
    {},
    true,
  )

  const { data: userResponseData, fetchData: getUserData } = useFetch<IUser | null>(
    'auth/users/me/',
    null,
    {},
    true,
  )

  useEffect(() => {
    if (!user) return
    getUserQuota()
    getUserData()
  }, [user])

  useEffect(() => {
    if (!quotaResponseData) return
    setQuota(quotaResponseData)
    setIsUserHasSubscription(true)
    navigate('/overview')
    // if (quotaResponseData.remaining_reviews > 0) {
    //   setIsUserHasSubscription(true)
    //   navigate('/overview')
    // } else {
    //   setIsUserHasSubscription(false)
    //   navigate('/subscription')
    // }
  }, [quotaResponseData])

  useEffect(() => {
    if (!userResponseData) return
    // @ts-ignore
    setUserLang(userResponseData.lang.toLowerCase())
  }, [userResponseData])

  useEffect(() => {
    amplitude.init('2bfbcf9f97a780b0315a90d10576aa44')
  }, [])

  return (
    <Container>
      <YMInitializer
        accounts={[97918628]}
        version={'2'}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />
      <IntlProvider locale={userLang} messages={messages[userLang]}>
        <GlobalStyles />
        <ThemeProvider theme={light}>
          <NiceModalProvider>
            <Header />
            <Outlet />
          </NiceModalProvider>
        </ThemeProvider>
      </IntlProvider>
    </Container>
  )
}

export default Layout
