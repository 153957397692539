import styled from 'styled-components'
export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; 
  gap: 24px;
  width: 100%;
  padding: 28px 32px;
  border-radius: 24px;
  background: ${p => p.theme.colors.blocks?.light};
`
export const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 1 48%;
  max-width: 48%;
`
export const NoDataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
