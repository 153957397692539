import React, { useState } from 'react'
import {
  AverageBlocksWrapper,
  ChartsWrapper,
  Container,
  NoDataOrLoaderWrapper,
  NpsChartWrapper,
  SentimentAndNpsWrapper,
  SentimentChartWrapper,
  TableWrapper,
  Wrapper,
} from './styled'
import SimpleAverageRating from 'Components/Charts/SimpleAvgRating'
import DiscussedTopics from 'Components/DiscussedTopics'
import TopItems from 'Components/TopItems'
import ItemsTable from './Components/ItemsTable'
import AverageSentiment from 'Components/Charts/AvgSentiment'
import Loader from 'Components/Kit/Loader'
import CreateGroup from 'Widgets/Overview/Components/CreateGroup'
import AddToGroup from 'Widgets/Overview/Components/AddToGroup'
import ComparisonNavigation from 'Components/ComparisonNav'
import SentimentLine from 'Components/Charts/SentimentLine'
import AddItem from '../AddItem'
import Groups from './Components/Groups'
import ItemsTableActions from './Components/ItemsTableActions'
import NiceModal from '@ebay/nice-modal-react'
import { useIdsStore } from 'Store/useIdsStore'
import useOverviewData from './Hooks/useOverviewData'
import { useFiltersStore } from '../../Store/useFiltersStore'
import EmptyData from './Components/EmptyData'
import useSelectedItemsStore from 'Store/useSelectedItemsStore'
import DeleteItems from './Components/DeleteItems'
import Notification from 'Components/Kit/Notification'
import { useRequest } from '../../Hooks/useRequest'
import NpsChart from '../../Components/Charts/NpsChart'
import SubHeaderWithFilters from '../../Components/SubHeaderWithFilters'
import { useTranslation } from '../../Hooks/useTranslation'
import Button from '../../Components/Kit/Button'
import { css, useTheme } from 'styled-components'
import Plus from '../../Assets/Plus'

const Overview = () => {
  const t = useTranslation()
  const theme = useTheme()
  const { activePeriod } = useFiltersStore()
  const { setComparisonIds, comparisonIds } = useIdsStore()
  const { setSelectedItems, selectedItems } = useSelectedItemsStore()

  const [topProductsType, setTopProductsType] = useState(0)
  const [discussedTopicsType, setDiscussedTopicsType] = useState(0)

  const [activeMarketplace, setActiveMarketplace] = useState<string>('')
  const [searchedItemQuery, setSearchedItemQuery] = useState('')
  const [isComparisonNavActive, setIsComparisonNavActive] = useState(false)

  const {
    items,
    topItems,
    discussedTopics,
    lineChartData,
    groups,
    fetchGroups,
    rating,
    sentiment,
    marketplaces,
    nps,
    fetchItems,
  } = useOverviewData({
    marketplace: activeMarketplace,
    date__gte: activePeriod.startDate,
    date__lte: activePeriod.endDate,
    topProductsType,
    discussedTopicsType,
  })

  const { requestData: deleteItems, isLoading: isDeleteLoading } = useRequest()
  const handleActiveMarketplaceChange = (marketplaceName: string) => {
    setActiveMarketplace(marketplaceName)
  }
  const handledDeleteItems = async () => {
    try {
      const itemsIds: number[] = selectedItems.map(item => item.id)
      await deleteItems('DELETE', '/products/bulk_delete/', { ids: itemsIds })
      await fetchItems()
      setSelectedItems([])
      void NiceModal.show(Notification, { message: 'Items deleted!' })
    } catch (e) {
      console.log(e)
    }
  }
  const handleComparisonClick = () => {
    const selectedItemsIds = selectedItems.map(item => item.id)
    setComparisonIds([...comparisonIds, ...selectedItemsIds])
    setIsComparisonNavActive(true)
  }
  const showAddItemModal = () => {
    NiceModal.show(AddItem).then(async () => {
      await fetchItems()
    })
  }
  const showCreateGroupModal = () => {
    if (!items) return
    NiceModal.show(CreateGroup, { items: items.results }).then(async () => {
      setSelectedItems([])
      await fetchItems()
      await fetchGroups()
    })
  }
  const showAddToGroupModal = () => {
    if (!(items && groups)) return
    NiceModal.show(AddToGroup, {
      items: items.results,
      groups: groups.results,
    }).then(async () => {
      await fetchItems()
      await fetchGroups()
      setSelectedItems([])
    })
  }
  const handleDeleteItems = () => {
    NiceModal.show(DeleteItems).then(() => handledDeleteItems())
  }

  const filteredItems =
    searchedItemQuery && items && items.results
      ? items.results
          .map(item => ({
            ...item,
            children: item.children?.filter(childrenItem =>
              childrenItem.details.text
                .toLowerCase()
                .startsWith(searchedItemQuery.toLowerCase()),
            ),
          }))
          .filter(item => item.children && item.children.length > 0)
      : items?.results

  return (
    <Container>
      {!items ? (
        <NoDataOrLoaderWrapper>
          <Loader size={25} />
        </NoDataOrLoaderWrapper>
      ) : (
        <Wrapper>
          {items && items.results.length ? (
            <>
              <SubHeaderWithFilters
                title={t('Overview.title')}
                marketplaces={marketplaces}
                handleActiveMarketplaceChange={handleActiveMarketplaceChange}
                extraActions={
                  <Button
                    onClick={showAddItemModal}
                    buttonSize={'large'}
                    fragment={css`
                      min-width: 120px;
                    `}
                  >
                    <Plus fill={theme.colors.icons.light} />
                    {t('Overview.addItem')}
                  </Button>
                }
              />
              <Groups
                groups={groups?.results || []}
                toggleCreateGroup={showCreateGroupModal}
              />
              <ChartsWrapper>
                <TopItems
                  items={topItems || []}
                  type={topProductsType}
                  setType={setTopProductsType}
                />
                <DiscussedTopics
                  topics={discussedTopics}
                  type={discussedTopicsType}
                  setType={setDiscussedTopicsType}
                />
                <AverageBlocksWrapper>
                  <SimpleAverageRating
                    rating={rating && rating.average_rating ? rating.average_rating : 0}
                    difference={rating && rating.difference ? rating.difference : 0}
                  />
                  <AverageSentiment
                    percentage={+(sentiment.score * 100).toFixed(0)}
                    difference={sentiment.difference}
                  />
                </AverageBlocksWrapper>
              </ChartsWrapper>
              <SentimentAndNpsWrapper>
                <SentimentChartWrapper>
                  <SentimentLine data={lineChartData?.results || []} />
                </SentimentChartWrapper>
                <NpsChartWrapper>
                  <NpsChart nps={nps} />
                </NpsChartWrapper>
              </SentimentAndNpsWrapper>
              <TableWrapper>
                <ItemsTableActions
                  deleteItems={handleDeleteItems}
                  isDeleteLoading={isDeleteLoading}
                  searchedItemQuery={searchedItemQuery}
                  setSearchedItemQuery={setSearchedItemQuery}
                  handleComparisonClick={handleComparisonClick}
                  toggleAddToGroup={showAddToGroupModal}
                />
                <ItemsTable items={filteredItems || []} />
              </TableWrapper>
            </>
          ) : (
            <NoDataOrLoaderWrapper>
              <EmptyData showAddItemModal={showAddItemModal} />
            </NoDataOrLoaderWrapper>
          )}
        </Wrapper>
      )}
      <ComparisonNavigation isActive={isComparisonNavActive} />
    </Container>
  )
}

export default Overview
