import React from 'react'
import styled from 'styled-components'
import SimpleDatePicker from 'Components/Kit/SimpleDatePicker'
import Select from 'Components/Kit/Select'
import { useTranslation } from 'Hooks/useTranslation'
import { IMarketplace } from 'Types/marketplace'

type FiltersProps = {
  handleActiveMarketplaceChange: (name: string) => void
  marketplaces: IMarketplace[]
}
export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  z-index: 8;
`
const Filters = (props: FiltersProps) => {
  const t = useTranslation()
  const { handleActiveMarketplaceChange, marketplaces } = props

  const modifiedMarketplaces = marketplaces.map(marketplace => ({
    label: marketplace.name,
    value: marketplace.name,
  }))

  return (
    <FilterWrapper>
      <SimpleDatePicker width={280} />
      <Select
        items={modifiedMarketplaces}
        onChange={handleActiveMarketplaceChange}
        title={t('Filters.allSources')}
      />
    </FilterWrapper>
  )
}

export default Filters
