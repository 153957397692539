import React from 'react'
import {
  RatingValue,
  RatingWrapper,
  Wrapper,
  Line,
  LineWrapper,
  LinesWrapper,
  ActiveLine,
} from './styled'
import Text from 'Components/Kit/Text'
import Star from 'Assets/Star'
import { useTheme } from 'styled-components'
import { IRating } from 'Types/rating'
import { useTranslation } from 'Hooks/useTranslation'

type AverageRatingProps = {
  rating: IRating
}

const AverageRating = ({ rating }: AverageRatingProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const fiveStar = ((rating.rating_5_count / rating.review_count) * 100).toFixed(0)
  const fourStar = ((rating.rating_4_count / rating.review_count) * 100).toFixed(0)
  const threeStar = ((rating.rating_3_count / rating.review_count) * 100).toFixed(0)
  const twoStar = ((rating.rating_2_count / rating.review_count) * 100).toFixed(0)
  const oneStar = ((rating.rating_1_count / rating.review_count) * 100).toFixed(0)

  return (
    <Wrapper>
      <RatingWrapper>
        <Text fontSize={20} fontWeight={500}>
          {t('Item.averageRating')}
        </Text>
        <RatingValue>
          <Text fontSize={36} fontWeight={500}>
            {rating.average_rating.toFixed(0)}
          </Text>
          <Star height={19} width={19} viewBox={'0 0 19 19'} />
        </RatingValue>
        <Text fontSize={13} fontWeight={400}>
          {t(`Item.basedOnReviews`, { count: rating.review_count })}
        </Text>
      </RatingWrapper>
      <LinesWrapper>
        <LineWrapper>
          <RatingValue>
            <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
              5
            </Text>
          </RatingValue>
          <Line>
            <ActiveLine width={+fiveStar} background={theme.colors.lines.green} />
          </Line>
          <Text fontSize={13} fontWeight={500}>
            {fiveStar}%
          </Text>
        </LineWrapper>
        <LineWrapper>
          <RatingValue>
            <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
              4
            </Text>
          </RatingValue>
          <Line>
            <ActiveLine width={+fourStar} background={theme.colors.lines.lightGreen} />
          </Line>
          <Text fontSize={13} fontWeight={500}>
            {fourStar}%
          </Text>
        </LineWrapper>
        <LineWrapper>
          <RatingValue>
            <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
              3
            </Text>
          </RatingValue>
          <Line>
            <ActiveLine width={+threeStar} background={theme.colors.lines.yellow} />
          </Line>
          <Text fontSize={13} fontWeight={500}>
            {threeStar}%
          </Text>
        </LineWrapper>
        <LineWrapper>
          <RatingValue>
            <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
              2
            </Text>
          </RatingValue>
          <Line>
            <ActiveLine width={+twoStar} background={theme.colors.lines.orange} />
          </Line>
          <Text fontSize={13} fontWeight={500}>
            {twoStar}%
          </Text>
        </LineWrapper>
        <LineWrapper>
          <RatingValue>
            <Text fontSize={14} fontWeight={400} color={theme.colors.text.gray}>
              1
            </Text>
          </RatingValue>
          <Line>
            <ActiveLine width={+oneStar} background={theme.colors.lines.red} />
          </Line>
          <Text fontSize={13} fontWeight={500}>
            {oneStar}%
          </Text>
        </LineWrapper>
      </LinesWrapper>
    </Wrapper>
  )
}

export default AverageRating
