import AvgSentimentText from 'Components/Charts/AvgSentiment/localization/AvgSentiment.en'
import ComparisonNavText from 'Components/ComparisonNav/localization/ComparisonNav.en'
import DiscussedTopicsText from 'Components/DiscussedTopics/localization/DiscussedTopics.en'
import DownloadReportText from 'Components/DownloadReport/localization/DownloadReport.en'
import FiltersText from 'Components/Filters/localization/Filters.en'
import SimpleDatePickerText from 'Components/Kit/SimpleDatePicker/localization/SimpleDatePicker.en'
import NoDataText from 'Components/Kit/NoData/localization/NoData.en'
import SentimentChartTooltipText from 'Components/Charts/SentimentChartTooltip/localization/SentimentChartTooltip.en'
import SimpleAvgRatingText from 'Components/Charts/SimpleAvgRating/localization/SimpleAvgRating.en'
import TopicsTableText from 'Components/TopicTable/localization/TopicTable.en'
import TopItemsText from 'Components/TopItems/localization/TopItems.en'
import OverviewText from 'Widgets/Overview/localization/Overview.en'
import ItemText from 'Widgets/Item/localization/Item.en'
import GroupText from 'Widgets/Group/localization/Group.en'
import ComparisonText from 'Widgets/Comparison/localization/Comparison.en'
import HeaderText from 'Widgets/Header/localization/Header.en'
import ReviewsFilterText from 'Components/ReviewsFilter/localization/ReviewsFilter.en'
import ReviewsModalText from 'Widgets/Modals/ReviewsModal/localization/ReviewsModal.en'
import AddItemModalText from 'Widgets/AddItem/Localization/AddItem.en'
import NpsChartText from 'Components/Charts/NpsChart/localization/Nps.en'
import SentimentLineChartText from 'Components/Charts/SentimentLine/localization/SentimentLine.en'
import ReviewsText from 'Widgets/Reviews/Localization/Reviews.en'
import FourPChartText from 'Components/Charts/FourPChart/localization/FourPChart.en'
import PerceptualMappingText from 'Components/Charts/PerceptualMappingChart/Localization/PerceptualMappingChart.en'

export default {
  ...AvgSentimentText,
  ...SentimentLineChartText,
  ...ComparisonNavText,
  ...DiscussedTopicsText,
  ...DownloadReportText,
  ...FiltersText,
  ...SimpleDatePickerText,
  ...NoDataText,
  ...SentimentChartTooltipText,
  ...SimpleAvgRatingText,
  ...TopicsTableText,
  ...TopItemsText,
  ...OverviewText,
  ...ItemText,
  ...HeaderText,
  ...GroupText,
  ...ComparisonText,
  ...ReviewsFilterText,
  ...ReviewsModalText,
  ...AddItemModalText,
  ...NpsChartText,
  ...ReviewsText,
  ...FourPChartText,
  ...PerceptualMappingText,
}
