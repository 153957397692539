import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { ChartWrapper } from './styled'
import { useTheme } from 'styled-components'
import NoData from 'Components/Kit/NoData'
import Text from 'Components/Kit/Text'
import { PopularTopicType } from 'Types/popularTopics'

type TopicDonutChartProps = {
  data: PopularTopicType[]
}
const TopicDonutChart = ({ data }: TopicDonutChartProps) => {
  const theme = useTheme()

  const filteredData = data.filter(item => item.value !== 0)

  const colors = [
    theme.colors.charts.red,
    theme.colors.charts.blue,
    theme.colors.charts.green,
    '#5752D1',
    '#F4C145',
    '#EC7A08',
    '#009596',
    '#B8BBBE',
  ]

  const isEmptyData = data.every(item => item.value === 0)

  return (
    <ChartWrapper>
      <Text fontSize={20} fontWeight={500}>
        Popular topics
      </Text>
      {!isEmptyData ? (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={filteredData}
              cx="50%"
              cy="50%"
              innerRadius={92}
              outerRadius={115}
              paddingAngle={1}
              dataKey="value"
              cornerRadius={20}
              labelLine={true}
              label={({ name, value }) => `${name}`}
              style={{
                fontSize: 14,
              }}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <NoData isTooltip={false} />
      )}
    </ChartWrapper>
  )
}

export default TopicDonutChart
