import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { ChartWrapper } from './styled'
import { NpsType } from 'Types/nps'
import { useTheme } from 'styled-components'
import NoData from 'Components/Kit/NoData'
import Text from 'Components/Kit/Text'

type NpsChartProps = {
  nps: NpsType | null
}
const NpsChart = ({ nps }: NpsChartProps) => {
  const theme = useTheme()

  const data = [
    { name: 'Detractors', value: nps?.detractor_count },
    { name: 'Passives', value: nps?.passive_count },
    { name: 'Promoters', value: nps?.promoter_count },
  ].filter(item => item.value !== 0)

  const colors = [
    theme.colors.charts.red,
    theme.colors.charts.blue,
    theme.colors.charts.green,
  ]

  const isEmptyData = data.every(item => item.value === 0)

  return (
    <ChartWrapper>
      <Text fontSize={20} fontWeight={500}>
        NPS
      </Text>
      {!isEmptyData ? (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={92}
              outerRadius={115}
              paddingAngle={5}
              dataKey="value"
              cornerRadius={20}
              labelLine={true}
              label={({ value }) => `${value.toFixed(0)}`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <text
              x="50%"
              y="48%"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="48"
            >
              {nps?.nps.toFixed(0)}
            </text>
            <text
              x="50%"
              y="52%"
              textAnchor="middle"
              dy="2em"
              dominantBaseline="middle"
              fontSize="16"
              fill={theme.colors.text.gray}
            >
              NPS points
            </text>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <NoData isTooltip={false} />
      )}
    </ChartWrapper>
  )
}

export default NpsChart
