import React, { useMemo } from 'react'
import { ColumnType } from 'Types/table'
import { IItem } from 'Types/product'
import Text from 'Components/Kit/Text'
import { useNavigate } from 'react-router-dom'
import { css } from 'styled-components'
import { ItemWrapper, StarWrapper, ReviewWrapper } from './styled'
import Table from 'Components/Kit/Table'
import Star from 'Assets/Star'
import Tag from 'Components/Kit/Tag'
import SentimentSimpleChart from 'Components/Charts/SentimentSimple'
import Sort from 'Assets/Sort'
import SentimentChartTooltip from 'Components/Charts/SentimentChartTooltip'
import ItemImage from 'Components/ItemImage'
import { useTranslation } from 'Hooks/useTranslation'
import { Tooltip } from 'antd'
import { getMarketplaceIcon } from 'Utils/marketplace'
import useSelectedItemsStore from 'Store/useSelectedItemsStore'

type ProductsTableProps = {
  items: IItem[]
}
const ItemsTable = ({ items }: ProductsTableProps) => {
  const t = useTranslation()
  const navigate = useNavigate()

  const columns: ColumnType<IItem>[] = useMemo(
    () => [
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('Overview.group').toUpperCase()}
          </Text>
        ),
        dataIndex: 'details',
        key: 'details',
        sorter: (a, b) => a.details.text.localeCompare(b.details.text),
        sortIcon: () => <Sort />,
        filterSearch: true,
        render: (details: { text: string; imgUrl: string }, record: IItem) => {
          const isDisabled =
            Object.values(record.totalReviewsPartition).every(value => value === 0) ||
            (record.nestingLevel === 0 && !record.id)
          const isCustomData = record.click_url.includes('custom')
          return (
            <ItemWrapper
              onClick={() => {
                if (isDisabled) return
                if (record.nestingLevel === 0) {
                  navigate(`/group/${record.id}`, { replace: true })
                  return
                }
                if (isCustomData) return
                if (record.nestingLevel === 2) {
                  window.open(record.click_url, '_blank')
                } else {
                  navigate(record.click_url, { replace: true })
                }
              }}
            >
              {record.nestingLevel === 1 && <ItemImage link={details.imgUrl} />}
              <Text
                fontSize={14}
                fontWeight={record.nestingLevel === 0 ? 700 : 400}
                styledFragment={css`cursor: pointer;
                  width: 230px;`}
              >
                {details.text.length >= 30
                  ? `${details.text.slice(0, 30)}...`
                  : details.text}
              </Text>
            </ItemWrapper>
          )
        },
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('Overview.source').toUpperCase()}
          </Text>
        ),
        dataIndex: 'details',
        key: 'details',
        render: (details: { source_link: string; source_name: string }) => {
          const isMultiple = details.source_name === 'Multiple'
          return isMultiple ? (
            <Text fontSize={14} fontWeight={400}>
              {t('Overview.multiple')}
            </Text>
          ) : (
            <>
              {details.source_name === 'Custom data' ? (
                <Text fontSize={14} fontWeight={400}>
                  -
                </Text>
              ) : (
                <img src={getMarketplaceIcon(details.source_name)} alt="" />
              )}
            </>
          )
        },
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('Overview.avgRating').toUpperCase()}
          </Text>
        ),
        dataIndex: 'avgScore',
        key: 'avgScore',
        sorter: (a: IItem, b: IItem) => a.avgScore - b.avgScore,
        sortIcon: () => <Sort />,
        render: (avgScore: number) => (
          <StarWrapper>
            <Star width={16} height={16} viewBox={'0 0 20 20'} />
            <Text fontSize={14} fontWeight={400}>
              {avgScore.toFixed(1)}
            </Text>
          </StarWrapper>
        ),
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('Overview.totalReviews').toUpperCase()}
          </Text>
        ),
        dataIndex: 'totalReviews',
        key: 'totalReviews',
        sorter: (a: IItem, b: IItem) => a.totalReviews - b.totalReviews,
        sortIcon: () => <Sort />,
        render: (totalReviews: number, record: IItem) => (
          <ReviewWrapper>
            <Text fontSize={14} fontWeight={400} styledFragment={css`width: 45px`}>
              {totalReviews}
            </Text>
            <Tag content={`+${record.diffCountReviews}`} withIcon={false} view={'gray'} />
          </ReviewWrapper>
        ),
      },
      {
        title: (
          <Text fontSize={10} fontWeight={700}>
            {t('Overview.toneOfReviews').toUpperCase()}
          </Text>
        ),
        dataIndex: 'totalReviewsPartition',
        key: 'totalReviewsPartition',
        render: (totalReviewsPartition: { p: number; n: number; i: number }) => (
          <Tooltip title={<SentimentChartTooltip sentiments={totalReviewsPartition} />}>
            <span>
              <SentimentSimpleChart data={totalReviewsPartition} />
            </span>
          </Tooltip>
        ),
      },
    ],
    [items.length, t],
  )

  return <Table columns={columns} tableData={items} isCheckboxesShow />
}

export default ItemsTable
