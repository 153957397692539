import AvgSentimentText from 'Components/Charts/AvgSentiment/localization/AvgSentiment.ru'
import NpsChartText from 'Components/Charts/NpsChart/localization/Nps.ru'
import ComparisonNavText from 'Components/ComparisonNav/localization/ComparisonNav.ru'
import DiscussedTopicsText from 'Components/DiscussedTopics/localization/DiscussedTopics.ru'
import DownloadReportText from 'Components/DownloadReport/localization/DownloadReport.ru'
import FiltersText from 'Components/Filters/localization/Filters.ru'
import SimpleDatePicker from 'Components/Kit/SimpleDatePicker/localization/SimpleDatePicker.ru'
import NoDataText from 'Components/Kit/NoData/localization/NoData.ru'
import SentimentChartTooltipText from 'Components/Charts/SentimentChartTooltip/localization/SentimentChartTooltip.ru'
import SimpleAvgRatingText from 'Components/Charts/SimpleAvgRating/localization/SimpleAvgRating.ru'
import TopicsTableText from 'Components/TopicTable/localization/TopicTable.ru'
import TopItemsText from 'Components/TopItems/localization/TopItems.ru'
import OverviewText from 'Widgets/Overview/localization/Overview.ru'
import ItemText from 'Widgets/Item/localization/Item.ru'
import GroupText from 'Widgets/Group/localization/Group.ru'
import ComparisonText from 'Widgets/Comparison/localization/Comparison.ru'
import HeaderText from 'Widgets/Header/localization/Header.ru'
import ReviewsFilterText from 'Components/ReviewsFilter/localization/ReviewsFilter.ru'
import ReviewsModalText from 'Widgets/Modals/ReviewsModal/localization/ReviewsModal.ru'
import AddItemModalText from 'Widgets/AddItem/Localization/AddItem.ru'
import SentimentLineChartText from 'Components/Charts/SentimentLine/localization/SentimentLine.ru'
import ReviewsText from 'Widgets/Reviews/Localization/Reviews.ru'
import FourPChartText from 'Components/Charts/FourPChart/localization/FourPChart.ru'
import PerceptualMappingText from 'Components/Charts/PerceptualMappingChart/Localization/PerceptualMappingChart.ru'

export default {
  ...AvgSentimentText,
  ...SentimentLineChartText,
  ...ComparisonNavText,
  ...DiscussedTopicsText,
  ...DownloadReportText,
  ...FiltersText,
  ...SimpleDatePicker,
  ...NoDataText,
  ...SentimentChartTooltipText,
  ...SimpleAvgRatingText,
  ...TopicsTableText,
  ...TopItemsText,
  ...OverviewText,
  ...ItemText,
  ...HeaderText,
  ...GroupText,
  ...ComparisonText,
  ...ReviewsFilterText,
  ...ReviewsModalText,
  ...AddItemModalText,
  ...NpsChartText,
  ...ReviewsText,
  ...FourPChartText,
  ...PerceptualMappingText,
}
