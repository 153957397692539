import React, { useEffect, useState } from 'react'
import {
  ChartsWrapper,
  Container,
  ContentWrapper,
  NoDataOrLoaderWrapper,
  SearchsWrapper,
  SubHeader,
  Wrapper,
} from './styled'
import Text from 'Components/Kit/Text'
import { useTranslation } from 'Hooks/useTranslation'
import { useFetch } from 'Hooks/useFetch'
import { IntersectionTopicsType } from 'Types/topic'
import PerceptualMappingChart from 'Components/Charts/PerceptualMappingChart'
import SimpleSearch from 'Components/SimpleSearch'
import { PerceptualMappingType } from 'Types/charts'
import Loader from 'Components/Kit/Loader'
import { SimpleItemType } from 'Types/product'
import Button from 'Components/Kit/Button'
import NiceModal from '@ebay/nice-modal-react'
import NotEnoughTopicsModal from './Components/NotEnoughTopicsModal'

const Comparison = () => {
  const t = useTranslation()

  const [activeTopics, setActiveTopics] = useState<{ id: string; name: string }[]>([])
  const [activeItems, setActiveItems] = useState<{ id: string; name: string }[]>([])
  const [activeStep, setActiveStep] = useState<'products' | 'topics'>('products')

  const { data: perceptualMappingData, fetchData: fetchPerceptualMappingData } = useFetch<
    PerceptualMappingType[] | null
  >(
    `plots/perceptual_mapping/?product_ids=${activeItems.map(item => +item.id)}`,
    null,
    {
      topic_x: activeTopics[0]?.name || '',
      topic_y: activeTopics[1]?.name || '',
    },
    true,
  )

  const { data: simpleProducts } = useFetch<SimpleItemType[] | null>(
    '/products/simple',
    null,
  )

  const { data: topics, fetchData: fetchIntersectionTopics } =
    useFetch<IntersectionTopicsType | null>(
      `topics/intersection/?product_ids=${activeItems.map(item => +item.id)}`,
      null,
      {},
      true,
    )

  const handleTopicChange = (topics: { id: string; name: string }[]) => {
    setActiveTopics(topics)
  }
  const handleItemChange = (items: { id: string; name: string }[]) => {
    setActiveItems(items)
  }
  const handleChooseTopics = async () => {
    await fetchIntersectionTopics()
    setActiveStep('topics')
  }
  const handleClearAll = () => {
    setActiveTopics([])
    setActiveItems([])
    setActiveStep('products')
    fetchPerceptualMappingData()
  }

  const modifiedTopics = topics?.intersection.map(topic => ({
    id: topic,
    name: topic,
  }))

  const modifiedItems = simpleProducts?.map(topic => ({
    id: topic.id.toString(),
    name: topic.name,
  }))

  useEffect(() => {
    if (topics && topics.intersection.length < 2) {
      NiceModal.show(NotEnoughTopicsModal).then(() => {
        setActiveStep('products')
        setActiveItems([])
        setActiveTopics([])
        fetchPerceptualMappingData()
      })
    }
  }, [topics])

  useEffect(() => {
    if (activeTopics.length >= 2) {
      fetchPerceptualMappingData()
    }
  }, [activeTopics])

  return (
    <Container>
      <Wrapper>
        {simpleProducts ? (
          <>
            {simpleProducts ? (
              <>
                <SubHeader>
                  <Text fontSize={36} fontWeight={500}>
                    {t('Comparison.title')}
                  </Text>
                </SubHeader>
                <ContentWrapper>
                  {activeStep === 'products' && (
                    <SearchsWrapper>
                      <SimpleSearch
                        elems={modifiedItems || []}
                        handleChange={handleItemChange}
                        activeElem={activeItems}
                        maxSelectableElems={4}
                      />
                      <Button
                        onClick={handleChooseTopics}
                        disabled={activeItems.length < 2}
                      >
                        Choose topics
                      </Button>
                    </SearchsWrapper>
                  )}
                  {activeStep === 'topics' && (
                    <SearchsWrapper>
                      <SimpleSearch
                        elems={modifiedTopics || []}
                        handleChange={handleTopicChange}
                        activeElem={activeTopics}
                        maxSelectableElems={2}
                      />
                      <Button onClick={handleClearAll} buttonType={'bordered'}>
                        Clear all
                      </Button>
                    </SearchsWrapper>
                  )}
                  <ChartsWrapper>
                    <PerceptualMappingChart
                      data={perceptualMappingData || []}
                      topic_x={activeTopics[0]?.name || ''}
                      topic_y={activeTopics[1]?.name || ''}
                    />
                  </ChartsWrapper>
                </ContentWrapper>
              </>
            ) : (
              <NoDataOrLoaderWrapper>{t('Comparison.noData')}</NoDataOrLoaderWrapper>
            )}
          </>
        ) : (
          <NoDataOrLoaderWrapper>
            <Loader size={25} />
          </NoDataOrLoaderWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

export default Comparison
