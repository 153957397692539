import React from 'react'
import {
  Wrapper,
  Title,
  MainProductWrapper,
  ProductImageWrapper,
  StarsAndSentimentWrapper,
  ItemsWrapper,
  MainIcon,
} from './styled'
import Text from 'Components/Kit/Text'
import Star from 'Assets/Star'
import SingleArrow from 'Assets/SingleArrow'
import { css, useTheme } from 'styled-components'
import Switcher from 'Components/Kit/Switcher'
import { ISimpleItem } from 'Types/product'
import { useTranslation } from 'Hooks/useTranslation'
import { Tooltip } from 'antd'
import { sliceText } from 'Utils/text'
import OtherItems from './OtherItems'
import { useNavigate } from 'react-router-dom'
import ItemImage from 'Components/ItemImage'

type TopProductsProps = {
  items: ISimpleItem[]
  setType: (value: number) => void
  type: number
}
const TopItems = ({ items, setType, type }: TopProductsProps) => {
  const t = useTranslation()
  const theme = useTheme()
  const buttons = [t('TopItems.best'), t('TopItems.worst')]
  const navigate = useNavigate()
  const goToProduct = (id: number) => {
    navigate(`/products/${id}`)
  }

  return (
    <Wrapper>
      <Title>
        <Text fontSize={20} fontWeight={500}>
          {t('TopItems.title')}
        </Text>
        <Switcher
          firstSelectedIndex={type}
          onChange={setType}
          buttons={buttons}
          width={120}
        />
      </Title>
      {Boolean(items.length) && (
        <ItemsWrapper>
          <MainProductWrapper>
            <ProductImageWrapper>
              <ItemImage link={items[0].icon_link} variant={'large'} />
              <Tooltip
                title={
                  <Text fontSize={10} fontWeight={400}>
                    {items[0].name}
                  </Text>
                }
              >
                <Text
                  onClick={() => goToProduct(items[0].id)}
                  fontSize={16}
                  fontWeight={700}
                  styledFragment={css`
                    width: 172px;
                    cursor: pointer;
                  `}
                >
                  {sliceText(24, items[0].name)}
                </Text>
              </Tooltip>
            </ProductImageWrapper>
            <StarsAndSentimentWrapper>
              <MainIcon>
                <Star width={16} height={16} viewBox={'0 0 20 20'} />
                <Text fontSize={14} fontWeight={400}>
                  {items[0].avg_rating?.toFixed(1)}
                </Text>
              </MainIcon>
              <MainIcon>
                {items[0].difference !== 0 && (
                  <>
                    <Text fontSize={14} fontWeight={400}>
                      {(Math.abs(items[0].difference) * 100)?.toFixed(0)}%
                    </Text>
                    <SingleArrow
                      fill={
                        items[0]?.difference >= 0
                          ? theme.colors.icons.green
                          : theme.colors.icons.red
                      }
                      rotate={items[0]?.difference >= 0 ? 180 : 0}
                    />
                  </>
                )}
              </MainIcon>
            </StarsAndSentimentWrapper>
          </MainProductWrapper>
          <OtherItems items={items} />
        </ItemsWrapper>
      )}
    </Wrapper>
  )
}
export default TopItems
