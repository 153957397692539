import styled from 'styled-components'

export const OtherItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`
export const RatingAndSentimentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`
export const ItemsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: 12px;
`
export const ProductListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`
export const RatingsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`
export const SentimentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`
export const TableIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  height: 40px;
`
